.question-index{
     text-align: center;
    font-weight: 500;



}

.answeredStyle {
    background-color: rgb(35, 168, 35); 
    color: white; 
}

.notAnsweredStyle {
    background-color: white;
    color: black; 
}

.markedForReviewStyle {
    background-color: #673AB7; 
    color: white; 
}

.skippedStyle {
    background-color:#FFFF00; 
    color: black; 

}


@media (max-width: 400px) {
     .AnswerStatus {
        width: 100%;
    }
}

@media (max-width: 400px) {
    .exam-container {
       width: 100%;
   }
}

@media print {
    body {
        margin: 0;
        padding: 0;
        font-size: 12pt; /* Adjust font size for print */
    }

    .scoreCard {
        page-break-inside: avoid; /* Prevent page breaks inside this div */
    }

    /* Prevent page breaks for specific sections */
    section {
        page-break-inside: avoid;
        page-break-after: auto;
    }

    /* Hide elements that should not be printed */
    .no-print {
        display: none;
    }

    /* Adjust table styles for printing */
    table {
        width: 100%;
        border-collapse: collapse;
    }

    th, td {
        border: 1px solid #000;
        padding: 8px;
        text-align: left;
    }
}