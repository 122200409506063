body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none; /* Prevents the rubber-band effect on mobile devices */
  height: 100%;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 400px) {
  body {
     width: fit-content;
    
 }
}

th::first-letter{
  text-transform: capitalize
}

label::first-letter{
  text-transform:capitalize;
}
input::placeholder {
  text-transform: capitalize;
}
a::first-letter{
  text-transform: capitalize
}
table{
  font-size: medium
} 


/* Apply to all scrollbars in the application */
* {
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: #a8a8a8 #f4f4f4; /* Customize thumb and track colors */
}

/* Webkit-based browsers (Chrome, Edge, Safari) */
*::-webkit-scrollbar {
  width: 4px; /* Width for a narrow scrollbar */
}

*::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #a8a8a8, #6b6b6b); /* Gradient effect for the thumb */
  border-radius: 50%; /* More rounded edges */
  transition: background-color 0.3s ease; /* Smooth transition */
}

*::-webkit-scrollbar-track {
  background: #f4f4f4; /* Light background for the track */
  border-radius: 50%; /* More rounded edges */
}

/* Hover effect to change thumb color */
*::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg, #8e8e8e, #555555);
}

/* Optional: Dark theme styles */
body.dark-theme * {
  scrollbar-color: #444444 #1c1c1c;
}

body.dark-theme *::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #555555, #333333);
}

body.dark-theme *::-webkit-scrollbar-track {
  background: #1c1c1c;
}

body.dark-theme *::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg, #666666, #444444);
}



@tailwind base;
@tailwind components;
@tailwind utilities;