.toast-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000000000000000;
  }
  
  @keyframes slideIn {
    0% {
      opacity: 0;
      transform: scale(0.9);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  @keyframes slideOut {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(0.9);
    }
  }
  
  .toast-enter {
    animation: slideIn 0.3s ease-out forwards;
  }
  
  .toast-exit {
    animation: slideOut 0.3s ease-in forwards;
  }
  