button{
  border: 1px solid black;
  padding: 2px;
}
input{
  
  border: 1px solid gray ;
  border-radius: 5px;
  padding: 7px;
}
.modal-overlayy {
  position: fixed; /* Ensures the modal stays in the viewport */
  top: 0;
  left: 0;
  width: 100vw; /* Full width of the viewport */
  height: 100vh; /* Full height of the viewport */
  background: rgba(0, 0, 0, 0.5); /* Dim background */
  display: flex;
  justify-content: center; /* Horizontal centering */
  align-items: center; /* Vertical centering */
  z-index: 1000; /* Place the modal above other elements like sidebar */
  overflow: hidden; /* Prevent scrolling in the background */

}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}