button{
  border: 1px solid black;
  padding: 2px;
}
input{
  
  border: 1px solid gray ;
  border-radius: 5px;
  padding: 7px;
}


/* Modal overlay */
.modal-overlayy {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal content */
.modal-contentt {
  background: white;
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  max-height: 80vh; /* Restrict the height */
  overflow-y: auto; /* Add scroll if content exceeds */

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Scrollable assessment list */
.assessment-list-container {
  max-height: 300px; /* Adjust height for scrollable area */
  overflow-y: auto;
}

/* Responsive design for mobile */
@media (max-width: 640px) {
  .modal-contentt {
      width: 95%;
    
  }

  .flex-col {
      flex-direction: column;
  }
}

/* General form responsiveness */
form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}


/* table css */
/* Tailwind CSS provides utility classes, so you can directly apply them to your JSX code. No additional custom CSS needed for basic responsiveness */

/* Ensure that the table wraps properly on smaller screens */
@media (max-width: 640px) {
  table {
      display: block;
      width: 100%;
      overflow-x: auto; /* Enable horizontal scrolling */
  }

  thead, tbody, tr, td, th {
      display: block; /* Make table elements block-level to stack vertically on smaller screens */
  }

  tr {
      margin-bottom: 1rem; /* Add spacing between rows */
  }

  th, td {
      text-align: left;
      padding: 10px;
      border: none; /* Remove borders for better readability on mobile */
  }

  th {
      background-color: #f0f0f0;
  }

  td::before {
      content: attr(data-label);
      font-weight: bold;
      text-transform: uppercase;
      padding-right: 10px;
      float: left; /* Mimic a two-column structure */
  }
}
