button{
  border: 1px solid black;
  padding: 2px;
}
input{
  
  border: 1px solid gray ;
  border-radius: 5px;
  padding: 7px;
}
.modal-overlayyy {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-contentt {
  background: white;
  border-radius: 8px;
  max-width: 50%;
  width: 100%;
  max-height: 90%; /* Modal height */
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .modal-contentt {
    max-width: 90%; /* Make the width 90% for screens smaller than 768px */
  }
}

.modal-header {
  background-color: #1e90ff;
  color: white;
  padding: 10px 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: sticky; /* Sticky header */
  top: 0;
  z-index: 10;
}

.modal-body {
  padding: 20px;
  overflow-y: auto; /* Scrollable content */
  max-height: 60vh; /* Set a fixed height for the body */
}

.modal-footer {
  padding: 10px 20px;
  text-align: right;
  background-color: #f1f1f1;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  position: sticky; /* Sticky footer if needed */
  bottom: 0;
  z-index: 10;
}
