button{
  border: 1px solid black;
  padding: 7px;
}
input{
  
  border: 1px solid gray ;
  border-radius: 5px;
  padding: 7px;
}
.modal-overlay {
  
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 15;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}