button{
  border: 1px solid black;
  padding: 2px;
}
input{
  
  border: 1px solid gray ;
  border-radius: 5px;
  padding: 7px;
}

.modal-overlay {
  position: fixed; /* Sticks the modal to the viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center; /* Horizontal centering */
  align-items: center; /* Vertical centering */
  z-index: 1000; /* Ensure the modal stays above other elements like sidebars */
  overflow: hidden; /* Prevent scrolling when the modal is open */
}


.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}